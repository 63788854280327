import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import PropTypes from "prop-types";
import classNames from "classnames";

import {
    Drawer,
    AppBar,
    Toolbar,
    List,
    Typography,
    IconButton,
    Grid,
    Fade,
    CssBaseline,
    withStyles,
    Button,
    Paper,
    Tabs,
    Tab
} from "@material-ui/core";

import SwipeableViews from 'react-swipeable-views';
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenusContainer from "./pages/menusPage/MenusContainer";
import UsersContainer from "./pages/usersPage/UsersContainer";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import ReportsContainer from "./pages/reportsPage/ReportsContainer";

import DashboardContainer from "./pages/dashboardPage/DashboardContainer";
import OpsDashboardContainer from "./pages/opsDashboardPage/OpsDashboardContainer";
import OpsStockContainer from "./pages/opsDashboardPage/OpsStockContainer";
import OpsOrdersContainer from "./pages/opsDashboardPage/OpsOrdersContainer";
import AccountContainer from "./pages/accountPage/AccountContainer";
import TerminalsContainer from "./pages/terminalsPage/TerminalsContainer";
import PromotionsContainer from "./pages/promotionsPage/PromotionsContainer";
import CashierUsersContainer from "./pages/cashierUsersPage/CashierUsersContainer";
import ScreenshotsContainer from "./pages/screenshotsPage/ScreenshotsContainer";
import GrafanaDashboardContainer from "./pages/dashboardPage/GrafanaDashboardContainer";
import KitchenFlowManagementContainer from "./pages/kitchenFlowsPage/KitchenFlowManagementContainer";

import { dashboardOperations } from "./duck";
import { loginOperations } from "./pages/loginPage/duck";
import {
    mainListItems,
    accountListItems,
    opsMainListItems,
    opsAccountListItems,
} from "./listItems";
import PortalSettings from "./utils/portalSettings";

// --- Reports

import OrdersReportContainer from "./pages/nativeReportsPage/OrdersReport/OrdersReportContainer";
import BasketsReportContainer from "./pages/nativeReportsPage/BasketsReport/BasketsReportContainer";
import EstateEventsReportContainer from "./pages/nativeReportsPage/EstateEventsReport/EstateEventsReportContainer";
import WallboardReportContainer from "./pages/nativeReportsPage/WallboardReport/WallboardReportContainer";
import OutOfStockReportContainer from "./pages/nativeReportsPage/OutOfStockReport/OutOfStockReportContainer";
import ItemsReportContainer from "./pages/nativeReportsPage/ItemsReport/ItemsReportContainer";
import TouchScreenOrdersReportContainer from "./pages/nativeReportsPage/TouchScreenOrdersReport/TouchScreenOrdersReportContainer";

import KioskMonitoringReportContainer from "./pages/nativeReportsPage/KioskMonitoringReport/KioskMonitoringReportContainer";

import "react-table-v6/react-table.css";
//import 'react-datepicker/dist/react-datepicker.css'

// ---
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (

                <div>{children}</div>

            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};



const drawerWidth = 260;

const styles = (theme) => ({
    root: {
        display: "flex",
    },
    loginText: {
        fontSize: "0.9em",
        marginRight: "0.9em",
        textAlign: "center",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    bottomMargin: {
        marginBottom: "20px"
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        color: theme.palette.common.white,
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        display: "none", // stash for now
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing() * 7,
            overflow: "hidden",
        },
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing() * 7,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing() * 9,
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing() * 3,
        height: "100vh",
        overflow: "auto",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing() * 1,
        },
    },
    sideMenu: {
        position: "absolute",
        top: theme.mixins.toolbar.minHeight + 10,
        width: "100%",

        "& a": {
            textDecoration: "none",

            "&.active > div": {
                backgroundColor: theme.palette.primary.main,

                "& span, & svg": {
                    color: "#FFF",
                },
            },
        },
        "& span": {
            fontSize: "14px",
        },
    },
    accountRoot: {
        padding: "0 12px 0 20px",
        boxShadow: "0 1px 4px rgba(0,0,0,0.4)",
        cursor: "pointer",
    },
    accountButton: {
        padding: "0",
    },
    accountText: {
        maxWidth: "calc(100% - 20px)",
        "& p": {
            "&:first-child": {
                fontWeight: "bold",
            },
            lineHeight: "16px",
        },
    },
    accountLogout: {
        color: "red",
    },
    version: {
        height: "100%",
    },
    showBlockingOverlay: {
        opacity: 0.5,
        background: "#fff",
        width: "100%",
        height: "100%",
        zIndex: 10000,
        top: 0,
        left: 0,
        position: "absolute"
    },
    hideBlockingOverlay: {
        display: "none"
    }
});

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 0,
            open: true,
            //Open account drawer automatically
            openAccount: this.props.location.pathname.indexOf("account") === 1,
        };
    }

    componentDidMount() {
        this.props.dispatch(dashboardOperations.getVersion());
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    onToggleAccount = () => {
        this.setState({ openAccount: !this.state.openAccount });
    };

    setTab = (event, tabIndex) => {
        this.setState({ selectedTab: tabIndex });
    };

    onLogout = () => {
        this.props.dispatch(loginOperations.logoutUser());
    };

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    render() {
        const { classes, login, dashboard } = this.props;
        const { selectedTab } = this.state;

        if (login && login.user && login.user.IsOpsUser) {
            return (
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar position="absolute">
                        <Toolbar className={classes.toolbar}>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                className={classes.title}
                            >
                                {PortalSettings.opsNameShort}
                            </Typography>

                            <Typography
                                display="block"
                                component="h6"
                                variant="h6"
                                color="inherit"
                                className={classes.loginText}
                            >
                                User<br /> <b>{login.user.Email}</b>
                            </Typography>

                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.onLogout}
                            >
                                Log out
              </Button>
                        </Toolbar>
                    </AppBar>

                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />

                        <Tabs
                            value={selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.setTab}
                            aria-label="disabled tabs example"
                            className={classes.bottomMargin}
                        >
                            <Tab label="Dashboard" />
                            <Tab label="Stock EI" />
                            <Tab label="Stock TA" />
                            <Tab label="Orders" />
                        </Tabs>

                        <SwipeableViews

                            index={selectedTab}
                            onChangeIndex={this.setTab}
                        >
                            <TabPanel value={selectedTab} index={0} >
                                <OpsDashboardContainer ></OpsDashboardContainer>
                            </TabPanel>
                            <TabPanel value={selectedTab} index={1} >
                                <OpsStockContainer termName={'EI'} title={"Eat-In"} ></OpsStockContainer>
                            </TabPanel>
                            <TabPanel value={selectedTab} index={2} >
                                <OpsStockContainer termName={'WC'} title={"Takeaway"} ></OpsStockContainer>
                            </TabPanel>
                            <TabPanel value={selectedTab} index={3} >
                                <OpsOrdersContainer ></OpsOrdersContainer>
                            </TabPanel>

                        </SwipeableViews>

                    </main>
                </div>
            );
        }



        if (login && login.user && !login.user.IsOpsUser) {
            return (
                <div className={classes.root}>
                    <CssBaseline />
                    <Drawer
                        variant="permanent"
                        open={this.state.open}
                        classes={{
                            paper: classNames(
                                classes.drawerPaper,
                                !this.state.open && classes.drawerPaperClose
                            ),
                        }}
                    >
                        <div className={dashboard.isSidebarDisabled ? classes.showBlockingOverlay : classes.hideBlockingOverlay}></div>
                        <Toolbar
                            classes={{ root: classes.accountRoot }}
                            onClick={this.onToggleAccount}
                        >
                            <Grid container wrap="nowrap" alignItems="flex-end">
                                <Grid item xs className={classes.accountText}>
                                    <Typography fontWeight="bold">
                                        {PortalSettings.nameShort}
                                    </Typography>

                                    <Typography noWrap>{login.user.Email}</Typography>
                                </Grid>

                                <Grid item xs="auto">
                                    <IconButton
                                        className={classes.accountButton}
                                        size="small"
                                        disableRipple={true}
                                    >
                                        {this.state.openAccount ? (
                                            <ArrowDropUpIcon />
                                        ) : (
                                                <ArrowDropDownIcon />
                                            )}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Toolbar>

                        <Fade
                            in={this.state.openAccount}
                            mountOnEnter={true}
                            unmountOnExit={true}
                        >
                            <List className={classes.sideMenu}>
                                {accountListItems(this.props, this.onLogout)}
                            </List>
                        </Fade>

                        <Fade
                            appear={true}
                            in={!this.state.openAccount}
                            mountOnEnter={true}
                            unmountOnExit={true}
                        >
                            <List className={classes.sideMenu}>
                                {mainListItems(this.props)}
                            </List>
                        </Fade>

                        <Grid container className={classes.version} alignItems="flex-end">
                            <Grid item xs={12}>
                                <Typography
                                    align="center"
                                    component="p"
                                    variant="subtitle2"
                                    gutterBottom
                                >
                                    Version {dashboard.version}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Drawer>

                    <main className={classes.content}>

                        <Switch>
                            <Route key="overview" path="/dashboard/overview"  >
                                <GrafanaDashboardContainer dashTitle="Overview" bounceKey='OVERVIEW'></GrafanaDashboardContainer>
                            </Route>

                            <Route key="mmopdash" path="/dashboard/mmop"  >
                                <GrafanaDashboardContainer dashTitle="Mobile Order and Pay Reports" bounceKey='MOP'></GrafanaDashboardContainer>
                            </Route>


                            <Route key="sopdash" path="/dashboard/sop"  >
                                <GrafanaDashboardContainer dashTitle="Self Order Point Reports" bounceKey='SOP'></GrafanaDashboardContainer>
                            </Route>
                            <Route key="cftdash" path="/dashboard/cft"  >
                                <GrafanaDashboardContainer dashTitle="Daily Reports" bounceKey='CFT'></GrafanaDashboardContainer>
                            </Route>
                            <Route key="digitaldash" path="/dashboard/digital"  >
                                <GrafanaDashboardContainer dashTitle="Digital Dashboard" bounceKey='DIGITAL'></GrafanaDashboardContainer>
                            </Route>
                            <Route key="npsdash" path="/dashboard/nps"  >
                                <GrafanaDashboardContainer dashTitle="NPS" bounceKey='NPS'></GrafanaDashboardContainer>
                            </Route>
                            <Route key="regionaldash" path="/dashboard/regional"  >
                                <GrafanaDashboardContainer dashTitle="Regional Reports" bounceKey='REGIONAL'></GrafanaDashboardContainer>
                            </Route>
                            <Route key="newlookdash" path="/dashboard/newlook"  >
                                <GrafanaDashboardContainer dashTitle="New Look Reports" bounceKey='NEWLOOK'></GrafanaDashboardContainer>
                            </Route>
                            <Route key="cafesignage" path="/dashboard/cafesignage"  >
                                <GrafanaDashboardContainer dashTitle="Signage" bounceKey='CAFESIGNAGE'></GrafanaDashboardContainer>
                            </Route>
                            <Route key="singlechanneloverview" path="/dashboard/singlechannel"  >
                                <GrafanaDashboardContainer dashTitle="Single Channel" bounceKey='SINGLECHANNEL'></GrafanaDashboardContainer>
                            </Route>

                            <Route key="cashledger" path="/dashboard/cashledger"  >
                                <GrafanaDashboardContainer dashTitle="Cash Ledger" bounceKey='CASHLEDGER'></GrafanaDashboardContainer>
                            </Route>

                            <Route key="weeklystats" path="/dashboard/weeklystats"  >
                                <GrafanaDashboardContainer dashTitle="Weekly Statistics" bounceKey='WEEKLYSTATS'></GrafanaDashboardContainer>
                            </Route>

                            <Route path="/menus" component={MenusContainer} />
                            <Route path="/reports" component={ReportsContainer} />

                            <Route path="/users" component={UsersContainer} />
                            <Route path="/account" component={AccountContainer} />
                            <Route path="/status" component={TerminalsContainer} />
                            <Route path="/promotions" component={PromotionsContainer} />
                            <Route path="/cashierusers" component={CashierUsersContainer} />

                            <Route
                                path="/nativereports/outofstock"
                                component={OutOfStockReportContainer}
                            />
                            <Route
                                path="/nativereports/orders"
                                component={OrdersReportContainer}
                            />

                            <Route
                                path="/screenshots"
                                component={ScreenshotsContainer}
                            />
                            <Route
                                path="/nativereports/baskets"
                                component={BasketsReportContainer}
                            />
                            <Route
                                path="/nativereports/items"
                                component={ItemsReportContainer}
                            />
                            <Route
                                path="/nativereports/touchscreenorders"
                                component={TouchScreenOrdersReportContainer}
                            />
                            <Route
                                path="/nativereports/estateevents"
                                component={EstateEventsReportContainer}
                            />
                            <Route
                                path="/nativereports/wallboard"
                                component={WallboardReportContainer}
                            />
                            <Route
                                path="/nativereports/kioskmonitoring"
                                component={KioskMonitoringReportContainer}
                            />

                            <Route path="/kitchenflows" component={KitchenFlowManagementContainer} />

                            <Route
                                path="/"

                            >
                                <GrafanaDashboardContainer dashTitle="Overview" bounceKey='OVERVIEW'></GrafanaDashboardContainer>
                            </Route>
                        </Switch>
                    </main>
                </div>
            );
        }
        return (<div>Loading..</div>);
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(
    withStyles(styles)(
        connect(({ dashboard, login }) => {
            return { dashboard, login };
        })(Dashboard)
    )
);
