import React from "react";
import { NavLink } from "react-router-dom";
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
} from "@material-ui/core";

import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import TimelineIcon from "@material-ui/icons/Timeline";
import TabletIcon from "@material-ui/icons/Tablet";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import AccountBoxIcon from "@material-ui/icons/AccountBoxRounded";
import LogoutIcon from "@material-ui/icons/Close";
import StoreIcon from "@material-ui/icons/Store";
import StarIcon from "@material-ui/icons/Star";
import TvIcon from "@material-ui/icons/Tv";
import BarChart from "@material-ui/icons/BarChart";
import TrendingUp from "@material-ui/icons/TrendingUpSharp";
import NpsIcon from "@material-ui/icons/BarChart";
import PortalSettings from "./utils/portalSettings";

import SmartphoneIcon from "@material-ui/icons/Smartphone";
import MotorCycleIcon from "@material-ui/icons/MotorcycleSharp";

import ComputerIcon from "@material-ui/icons/Computer";

import { checkRole } from "./utils/userUtils";
import { Kitchen } from "@material-ui/icons";

export const accountListItems = (props, onLogout) => (
    <React.Fragment>
        <NavLink to="/account">
            <ListItem button>
                <ListItemIcon>
                    <AccountBoxIcon />
                </ListItemIcon>

                <ListItemText primary="Account" />
            </ListItem>
        </NavLink>

        <ListItem button onClick={onLogout}>
            <ListItemIcon>
                <LogoutIcon />
            </ListItemIcon>

            <ListItemText primary="Log out" />
        </ListItem>
    </React.Fragment>
);

export const mainListItems = (props) => (
    <React.Fragment>
        <NavLink to="/dashboard/overview">
            <ListItem button>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
        </NavLink>

        {PortalSettings.mk == false && (
            <div>
                <NavLink to="/dashboard/digital">
                    <ListItem button>
                        <ListItemIcon>
                            <TrendingUp />
                        </ListItemIcon>
                        <ListItemText primary="Digital Dashboard" />
                    </ListItem>
                </NavLink>

                <NavLink to="/dashboard/mmop">
                    <ListItem button>
                        <ListItemIcon>
                            <TrendingUp />
                        </ListItemIcon>
                        <ListItemText primary="MOP Reports" />
                    </ListItem>
                </NavLink>

                <NavLink to="/dashboard/sop">
                    <ListItem button>
                        <ListItemIcon>
                            <TrendingUp />
                        </ListItemIcon>
                        <ListItemText primary="SOP Reports" />
                    </ListItem>
                </NavLink>

                <NavLink to="/dashboard/cft">
                    <ListItem button>
                        <ListItemIcon>
                            <TrendingUp />
                        </ListItemIcon>
                        <ListItemText primary="Daily Reports" />
                    </ListItem>
                </NavLink>

                <NavLink to="/dashboard/regional">
                    <ListItem button>
                        <ListItemIcon>
                            <TrendingUp />
                        </ListItemIcon>
                        <ListItemText primary="Regional Reports" />
                    </ListItem>
                </NavLink>
                <NavLink to="/dashboard/newlook">
                    <ListItem button>
                        <ListItemIcon>
                            <TrendingUp />
                        </ListItemIcon>
                        <ListItemText primary="New Look Reports" />
                    </ListItem>
                </NavLink>
                <NavLink to="/dashboard/nps">
                    <ListItem button>
                        <ListItemIcon>
                            <NpsIcon />
                        </ListItemIcon>
                        <ListItemText primary="NPS" />
                    </ListItem>
                </NavLink>
                <NavLink to="/dashboard/cafesignage">
                    <ListItem button>
                        <ListItemIcon>
                            <TvIcon />
                        </ListItemIcon>
                        <ListItemText primary="Signage" />
                    </ListItem>
                </NavLink>

            </div>
        )}

        {PortalSettings.mk == true && (
            <div>
                <NavLink to="/dashboard/digital">
                    <ListItem button>
                        <ListItemIcon>
                            <TrendingUp />
                        </ListItemIcon>
                        <ListItemText primary="Digital Dashboard" />
                    </ListItem>
                </NavLink>
                <NavLink to="/dashboard/mmop">
                    <ListItem button>
                        <ListItemIcon>
                            <TrendingUp />
                        </ListItemIcon>
                        <ListItemText primary="MOP Reports" />
                    </ListItem>
                </NavLink>
                <NavLink to="/dashboard/singlechannel">
                    <ListItem button>
                        <ListItemIcon>
                            <MotorCycleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Single Channel" />
                    </ListItem>
                </NavLink>
            </div>
        )}







        {PortalSettings.mk == true && (
            <div>
                <NavLink to="/dashboard/cashledger">
                    <ListItem button>
                        <ListItemIcon>
                            <TimelineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Cash Ledger" />
                    </ListItem>
                </NavLink>
                <NavLink to="/dashboard/weeklystats">
                    <ListItem button>
                        <ListItemIcon>
                            <TimelineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Weekly Statistics" />
                    </ListItem>
                </NavLink>
            </div>

        )}

        <Divider />

        <ListItem button disabled>
            <ListItemIcon>
                <StoreIcon />
            </ListItemIcon>
            <ListItemText primary="Stores" />
        </ListItem>

        <ListItem button disabled>
            <ListItemIcon>
                <TabletIcon />
            </ListItemIcon>
            <ListItemText primary="Terminals" />
        </ListItem>

        {!checkRole(props.login, "Reporting") && (
            <NavLink to="/status">
                <ListItem button>
                    <ListItemIcon>
                        <ComputerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Status" />
                </ListItem>
            </NavLink>
        )}

        {/* <NavLink to='/menus'> */}
        <ListItem button disabled>
            <ListItemIcon>
                <FastfoodIcon />
            </ListItemIcon>
            <ListItemText primary="Menus" />
        </ListItem>
        {/* </NavLink> */}

        {checkRole(props.login, "Editor") && (
            <NavLink to="/promotions">
                <ListItem button>
                    <ListItemIcon>
                        <StarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Promotions" />
                </ListItem>
            </NavLink>
        )}

        <NavLink to="/nativereports/wallboard">
            <ListItem button>
                <ListItemIcon>
                    <BarChart />
                </ListItemIcon>
                <ListItemText primary="Wallboard" />
            </ListItem>
        </NavLink>

        {PortalSettings.showScreenshots && (
            <NavLink to="/screenshots">
                <ListItem button>
                    <ListItemIcon>
                        <TvIcon />
                    </ListItemIcon>
                    <ListItemText primary="Screenshots" />
                </ListItem>
            </NavLink>
        )}

        <NavLink to='/kitchenflows'>
            <ListItem button>
                <ListItemIcon>
                    <Kitchen />
                </ListItemIcon>
                <ListItemText primary="KitchenFlow" />
            </ListItem>
        </NavLink>

        {checkRole(props.login, "Editor") && (
            <NavLink to="/users">
                <ListItem button>
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                </ListItem>
            </NavLink>
        )}

        {checkRole(props.login, "Editor") && (
            <NavLink to="/cashierusers">
                <ListItem button>
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cashier Users" />
                </ListItem>
            </NavLink>
        )}
    </React.Fragment>
);
